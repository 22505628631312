<template>
    <zw-sidebar @shown="shown" :title="$t('common.title.table_configuration')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="5">

                        <b-form-input v-model="search"
                                      style="border-bottom-right-radius: 0;border-bottom-left-radius: 0;border-bottom:0px"
                                      :placeholder="$t('common.label.common_search')"
                        ></b-form-input>

                        <b-form-select v-model="selected"
                                       :select-size="30"
                                       multiple
                                       style="border-top-right-radius: 0;border-top-left-radius: 0;"
                        >
                            <b-form-select-option-group v-for="(group,group_index) in columnsStructured"
                                                        :label="group.label"
                                                        :key="'group_'+group_index"
                            >
                                <b-form-select-option v-for="option in group.options"
                                                      :label="option.text"
                                                      :value="option.value"
                                                      :key="'option_'+option.value"
                                                      @dblclick="addToEnabled(option.value)"
                                                      :class=" (columnGroups[option.value.replace('base.','')].length ? 'text-info' : (option.value.replace('base.','').startsWith('_custom_') ? 'text-warning' : 'text-success'))"
                                ></b-form-select-option>
                            </b-form-select-option-group>
                        </b-form-select>
                    </b-col>

                    <b-col cols="1" class="text-center">
                        <b-button variant="success"
                                  class="mb-3"
                                  size="sm"
                                  :disabled="!selected.length"
                                  @click="addSelected"
                        >
                            <font-awesome-icon :icon="['fas', 'arrow-circle-right']"/>
                        </b-button>
                        <b-button variant="danger"
                                  class="mb-3"
                                  size="sm"
                                  :disabled="!enabledSelected.length"
                                  @click="deleteSelected"
                        >
                            <font-awesome-icon :icon="['fas', 'arrow-circle-left']"/>
                        </b-button>
                        <b-button variant="primary"
                                  class="mb-3"
                                  size="sm"
                                  @click="deleteRelations"
                        >
                            <font-awesome-icon :icon="['fas', 'trash']"/>
                        </b-button>
                    </b-col>

                    <b-col cols="6">
                        <b-list-group class="columns-list" style="height: 583px;overflow: auto">
                            <draggable :class="{[`cursor-grabbing`]: dragging === true}"
                                       v-model="enabledColumns"
                                       group="columns"
                                       @start="dragging = true"
                                       @end="dragging = false"
                                       tag="div"
                                       handle=".handle"
                            >
                                <b-list-group-item v-for="column in enabledColumns"
                                                   :key="column"
                                                   :class="'prevent-select '+ (columnGroups[column].length ? 'text-info' : (column.startsWith('_custom_') ? 'text-warning' : 'text-success'))"
                                                   :title="column"
                                                   :active="enabledSelected.includes(column)"
                                                   @mouseenter="(event)=>{selectEnabled(event,column)}"
                                                   @mousedown="()=>{selectEnabled({buttons:1},column)}"
                                                   @dblclick="deleteEnabled(column)"

                                >
                                <span class="handle" @mousedown="dragging = true">
                                    <font-awesome-icon class="mr-2" icon="grip-horizontal"></font-awesome-icon>
                                </span>
                                    <span v-for="group in columnGroups[column]">{{ group }}<font-awesome-icon
                                        class="ml-1 mr-1" icon="chevron-right"/></span>{{
                                        $t(columnsLabels[column])
                                    }}
                                </b-list-group-item>
                            </draggable>
                        </b-list-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-button class="mt-2" block @click="save" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import Draggable from "vuedraggable"
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    components: {
        Draggable
    },
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            dragging: false,
            columns: [],
            columnsLabels: {},
            columnGroups: {},
            payload: {},
            callback: null,

            menu: [],
            menu2: {},
            search: null,
            selected: [],
            enabledSelected: [],
            disabledColumns: [],
            enabledColumns: [],
        }
    },
    computed: {
        columnsStructured() {
            this.structureMenu(this.menu, 'base')
            let list = this.getElements(this.menu2, null)

            let filteredList = []
            list.forEach(group => {
                let options = {}

                let groupOptions = group.options.sort((a, b) => {
                    let fa = a.text.toLowerCase(),
                        fb = b.text.toLowerCase();

                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                })

                Object.keys(groupOptions).forEach(key => {
                    if (
                        !this.search ||
                        group.options[key].text.toLowerCase().includes(this.search.toLowerCase()) ||
                        group.options[key].value.toLowerCase().includes(this.search.toLowerCase())
                    ) {
                        options[key] = group.options[key]
                    } else {
                        this.selected = this.selected.filter(selectedKey => {
                            return selectedKey != group.options[key].value
                        })
                    }
                })

                filteredList.push({
                    label: group.label,
                    options: options,
                })
            })

            return filteredList


            return list
        }
    },
    methods: {
        addToEnabled(value) {
            this.enabledColumns.push(value.replace('base.', ''))
            this.selected = []
        },
        addSelected() {
            this.selected.forEach(value => {
                this.enabledColumns.push(value.replace('base.', ''))
            })
            this.selected = []
        },
        deleteSelected() {
            this.enabledColumns = this.enabledColumns.filter(value => {
                return !this.enabledSelected.includes(value)
            })
            this.enabledSelected = []
        },
        deleteRelations() {
            this.enabledColumns = this.enabledColumns.filter(value => {
                return !this.columnGroups[value].length
            })
        },
        deleteEnabled(key) {
            this.enabledColumns = this.enabledColumns.filter(value => {
                return key != value
            })
            this.enabledSelected = []
        },
        selectEnabled(event, key) {
            if (event.buttons === 1 && this.dragging == false) {
                if (this.enabledSelected.includes(key)) {
                    this.enabledSelected = this.enabledSelected.filter(value => {
                        return value != key
                    })
                } else {
                    this.enabledSelected.push(key)
                }
            }
        },
        structureMenu(menu, prev) {
            Object.keys(menu).forEach(key => {
                if (menu[key]) {
                    this.structureMenu(menu[key], prev + '.' + key)
                } else {
                    if (!this.menu2[prev]) {
                        this.menu2[prev] = {}
                    }
                    this.menu2[prev][key] = null
                }
            })
        },
        getElements(items, prev) {
            let options = []
            Object.keys(items).forEach((key) => {
                if (items[key]) {
                    options.push({
                        label: ((prev ? prev + '.' : '') + key).replace('base.', ''),
                        options: this.getElements(items[key], (prev ? prev + '.' : '') + key)
                    })
                } else {
                    if (!this.enabledColumns.includes(((prev ? prev + '.' : '') + key).replace('base.', ''))) {
                        options.push({
                            value: (prev ? prev + '.' : '') + key,
                            text: this.$t(this.columnsLabels[((prev ? prev + '.' : '') + key).replace('base.', '')]),
                        })
                    }

                }
            })

            return options.sort((a, b) => {
                return a.text - b.text;
            })

            // return options
        },
        shown() {
            axios.post(window.apiUrl + '/table-columns', {
                table: this.payload.table,
                tableFormat: this.payload.tableFormat,
                tableUniqKey: this.payload.tableUniqKey,
                selectedFilterId: this.payload.selectedFilterId,
            }, {'skip_loading': true})
                .then(result => {
                    Object.keys(result.data.columns).forEach((column) => {
                        if (result.data.columns[column]) {
                            this.enabledColumns.push(column)
                        } else {
                            this.disabledColumns.push(column)
                        }
                    })

                    this.columns = result.data.columns
                    this.columnsLabels = result.data.labels
                    this.columnGroups = result.data.groups
                    this.menu = result.data.menu

                    this.loading = false
                }, () => {
                    this.loading = false
                })
        },
        save() {
            let ordering = {}
            let loop = 0
            let columns = {}

            this.enabledColumns.forEach(column => {
                ordering[column] = loop
                columns[column] = true

                loop += 1
            })

            Object.keys(this.columns).forEach(column => {
                if (!this.enabledColumns.includes(column)) {
                    columns[column] = false
                    ordering[column] = loop

                    loop += 1
                }
            })

            axios.post(window.apiUrl + '/save-table-columns', {
                table: this.payload.table,
                tableUniqKey: this.payload.tableUniqKey,
                tableFormat: this.payload.tableFormat,
                selectedFilterId: this.payload.selectedFilterId,
                columns,
                ordering,
            }, {'skip_loading': true})
                .then(result => {
                    this.commonAfterSave(result)
                }, () => {
                    this.loading = false
                })
        },
    },
}
</script>

<style>
.columns-table td {
    padding-top: 2px;
    padding-bottom: 2px;
}

.sortable-ghost {
    background-color: lightblue !important;
}

.columns-list .list-group-item {
    padding-top: 0px;
    padding-bottom: 0px;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
</style>